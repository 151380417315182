<script setup lang="ts">
import type { WorkOrder } from '@/types/WorkOrder';
import type { SpecSheet } from '@/types/SpecSheet';
import ChangeImageModal from '~/pages/production/work-order/components/ChangeImageModal.vue';
import { productTypes } from '~/utils/productTypes';

const route = useRoute();
const id = route.query.id as string;

// PROPS
interface Props {
    pageTitle?: string | null;
}
withDefaults(defineProps<Props>(), {
    pageTitle: null,
});

// VARIABLE
const loaded = ref<boolean>(false);
const workOrder = ref<WorkOrder>({} as WorkOrder);
const specSheet = ref<SpecSheet>({} as SpecSheet);
const PageTitle = ref();
const projectImages = ref();
const imageSelected = ref({
    default: '',
    isSelect: '',
});

//EMIT
type Emit = { print: [specSheet: SpecSheet] };
defineEmits<Emit>();

// FUNCTION
async function getWorkOrder(): Promise<WorkOrder> {
    try {
        const { data, error } = await useApiFetch<WorkOrder>(`/v1/work-orders/${id}`);

        if (error.value) throw error.value;
        if (!data.value) throw new Error('NO WORK ORDER DATA');

        return data.value;
    } catch (err) {
        useBugsnag().notify(JSON.stringify(err));
        return {} as WorkOrder;
    }
}

async function getSpecSheet(): Promise<SpecSheet> {
    try {
        const { data, error } = await useApiFetch<SpecSheet>(`/v1/spec-sheets/${workOrder.value.specSheetId}`, {
            query: {
                include: 'project',
            },
        });
        if (error.value) throw error.value;
        if (!data.value) throw new Error('NO SPEC SHEET DATA @SPEC-SHEET VIEW');

        return data.value;
    } catch (err) {
        useBugsnag().notify(JSON.stringify(err));
        return {} as SpecSheet;
    }
}

function setBrowserTabTitle() {
    useHead({
        title: `${PageTitle.value}: ${workOrder.value.id}`,
    });
}

function onFileInputChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files[0]) {
        imageSelected.value.isSelect = URL.createObjectURL(target.files[0]);
    }
}

function changeImageInput(event: Event | string) {
    if (typeof event !== 'string') {
        const target = event.target as HTMLInputElement;
        imageSelected.value.isSelect = target.value;
    } else {
        imageSelected.value.isSelect = event;
    }
}

function setDefaultImage(productType: string) {
    const { bag, pants } = productTypes;
    const tShirtDefault = '/image/work-order/pt-shirt.png';
    const pantsDefault = '/image/work-order/pt-short.png';
    const bagDefault = '/image/work-order/pt-bag.png';
    const isBag = bag.some((product) => product.trim().includes(productType));
    const isPants = pants.some((product) => product.trim().includes(productType));

    switch (true) {
        case isBag:
            return bagDefault;
        case isPants:
            return pantsDefault;
        default:
            return tShirtDefault;
    }
}

onMounted(async () => {
    workOrder.value = await getWorkOrder();
    specSheet.value = await getSpecSheet();
    setBrowserTabTitle();
    loaded.value = true;
    projectImages.value = specSheet.value.img;
    imageSelected.value.default = setDefaultImage(specSheet.value.productType);
});
</script>
<template>
    <div>
        <div v-if="!loaded" class="z-50 fixed top-0 left-0 bg-black dark:bg-white bg-opacity-50 w-screen h-screen flex justify-center items-center">
            <span class="loading loading-spinner text-primary w-[4rem]" />
        </div>
        <div class="fixed top-0 left-0 right-0" :load="(PageTitle = pageTitle)">
            <div class="w-screen h-screen bg-white dark:bg-neutral-900 dark:text-white overflow-x-hidden">
                <div class="sticky top-0 flex items-center justify-between px-4 py-2 dark:bg-black dark:border-gray-600 bg-white d-none-print">
                    <div class="flex items-center gap-4">
                        <h3 v-if="pageTitle" class="text-xl font-medium text-gray-900 dark:text-white">{{ pageTitle }}</h3>
                        <slot name="customInput" />
                        <button
                            type="button"
                            class="flex items-center gap-1 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-1 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                            @click="$emit('print', specSheet)"
                        >
                            <IconCSS name="material-symbols:print-rounded" size="1.5rem" />
                            <span> Print </span>
                        </button>
                    </div>
                    <NuxtLink
                        :to="'/production/work-order/view?id=' + workOrder.id"
                        class="hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm gap-1 p-1 ms-auto flex justify-center items-center dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                    >
                        <IconCSS name="material-symbols:arrow-back-ios-new-rounded" size="1.25rem" />
                        <span>ย้อนกลับ</span>
                    </NuxtLink>
                </div>

                <main>
                    <slot :work-order="workOrder" :spec-sheet="specSheet" :page-title="pageTitle" :project-images="projectImages" :image-selected="imageSelected" />
                </main>
            </div>
        </div>

        <ChangeImageModal v-if="projectImages" :project-images="projectImages" @change-image-input="(input) => changeImageInput(input)" @on-file-input-change="(file) => onFileInputChange(file)" />
    </div>
</template>
<style>
@media print {
    * {
        color: #000 !important;
    }

    th,
    td {
        height: 5px;
    }

    select {
        border: 0;
        color: black;
    }

    ::-webkit-scrollbar {
        width: 0;
    }

    .shadow {
        box-shadow: 0 0 #0000;
    }

    .hide {
        display: block;
    }

    .d-none-print {
        display: none;
    }
}
</style>
